import React, { useState } from "react";
import {
    Navbar,
    Container,
    Nav,
    Row,
    Col,
    Button,
    Form,
    InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import apiPath from "../utilities/apiPath";
import { axiosPrivate } from "../utilities/axios";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
const Footer = () => {
    const [inputValue, setInputValue] = useState({
        email: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErros] = useState({
        EmailError: "",
    });

    const HandleOnChange = (e) => {
        setInputValue({ ...inputValue, [e.target.name]: e.target.value });
    };

    const HandleSave = async () => {
        setIsLoading(true);
        const isValid = validate();
        if (isValid) {
            try {
                const path = `${apiPath.newsletter_subscription}`;
                const response = await axiosPrivate.post(path, inputValue);
                const { message, success } = response.data;
                if (success) {
                    setInputValue({ ...inputValue, email: "" });
                    toast.success(message);
                    setIsLoading(false);
                } else {
                    toast.error(message);
                    setIsLoading(false);
                }
            } catch (error) {
                toast.error(error.message);
                setIsLoading(false);
            }
        }
    };

    const validate = () => {
        let EmailError = "";
        // let regex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!inputValue.email) {
            EmailError = "Email Field is required.";
        } else if (!regex.test(inputValue.email)) {
            EmailError = "Please enter valid email address.";
        }
        if (EmailError) {
            setErros({
                EmailError: EmailError,
            });
            return false;
        } else {
            setErros({
                EmailError: "",
            });
            return true;
        }
    };
    return (
        <>
            <div className="footer mt-3">
                <Container>
                    <div className="input-group-wrapper">
                        <InputGroup className="mb-2">
                            <p
                                style={{
                                    marginRight: "20px",
                                    marginTop: "10px",
                                    textAlign: "center",
                                    fontSize: "larger",
                                }}
                            >
                                Newsletter
                            </p>

                            <Form.Control
                                placeholder="Your email address"
                                type="email"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                name="email"
                                onChange={(e) => HandleOnChange(e)}
                                value={inputValue.email}
                            />
                            <Button
                                variant="outline-primary"
                                id="button-addon2"
                                onClick={() => HandleSave()}
                            >
                                {isLoading ? (
                                    <CircularProgress size={30} />
                                ) : (
                                    "Subscribe"
                                )}
                            </Button>
                        </InputGroup>
                        <div className="newseletter-error">
                            <p>{errors.EmailError}</p>
                        </div>
                    </div>
                    <Row className="align-items-center">
                        <Col
                            md={3}
                            className="text-center mb-md-0 mb-4 footer_logo"
                        >
                            <a href="#" className="float-md-start">
                                <img src="../images/logo.svg" alt="" />
                            </a>
                        </Col>

                        <Col md={6} className="mb-md-0 mb-4">
                            <ul className="d-flex text-center justify-content-sm-between justify-content-around opt_blog p-0 m-0">
                                <li>
                                    <Link to={"business"}>About Us</Link>
                                </li>
                                <li>
                                    <Link to={"subscription"}>
                                        Subscription
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"blogs"}>Blog</Link>
                                </li>
                                <li>
                                    <Link to={"faq"}>FAQ</Link>
                                </li>
                                <li>
                                    <Link to={"privacy-policy"}>
                                        Privacy Policy
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <ul className="social d-flex justify-content-center">
                                <li>
                                    <a
                                        href="https://twitter.com/i/flow/login"
                                        target="_blank"
                                    >
                                        <img
                                            src="../images/twitter.svg"
                                            alt=""
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://in.linkedin.com/"
                                        target="_blank"
                                    >
                                        <img
                                            src="../images/linkdin.svg"
                                            alt=""
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.facebook.com/"
                                        target="_blank"
                                    >
                                        <img
                                            src="../images/facebook.svg"
                                            alt=""
                                        />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/finding_trusted_care"
                                        target="_blank"
                                    >
                                        <img
                                            src="../images/instagram.svg"
                                            alt=""
                                        />
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <p className="disclaimer-p">
                        Disclaimer : Finding Trusted Care, LLC, and
                        Findingtrustedcare.com (the “Company”) does not employ
                        any caregiver and is not responsible for the conduct of
                        any user on or of our site. All information in member
                        profiles, job posts, applications, and messages is
                        created and submitted by the users of our site and not
                        generated, moderated, or verified by the Company, except
                        as is specifically set forth on the website. All users
                        need to do their own diligence to ensure the job or
                        caregiver you choose is appropriate for your needs and
                        complies with applicable laws.
                    </p>
                </Container>
            </div>
            <div className="copyright text-center">
                <Container>
                    <span>
                        © Copyright 2022 - Finding Trusted Care. All Rights
                        Reserved.
                    </span>
                </Container>
            </div>
        </>
    );
};

export default Footer;
